@import 'resetCss';
@import 'select';
$footer_size: 18px;
$header_size:150px;
html, body {
    margin: 0; 
    padding: 0; 
    height: 100%;

}
.navi{
    max-width: 1000px;
    margin: 0 auto;
    .navi_content{
        display: flex;
        justify-content: flex-end;
        .navi_button{
            min-width:80px;
            padding:0 10px;
            height:25px;
            line-height: 25px;
            border:1px solid #ddd;
            text-align: center;
            cursor:pointer;
        }
        .selected{
            background-color: black;
            color:white;
        }
    }
}


.default_button{
    width:80px;
    height:25px;
    border:1px solid #ddd;
    background:white;
    cursor:pointer;
}
.wrap {
    min-width: 800px; /* 최소 가로길이 800px */
    min-height: 100%; 
    position: relative;

}
.header{
    height: $header_size;
    text-align: center;
    img{
        height: 100%;
    }
        
}

.slidecontainer{//슬라이드바
    display:flex;
    flex-direction: column;
    align-items: center;
    input{
        width:290px;
    }
    
}

.content{
    max-width: 1000px; /* 최대 가로길이 1200px */
    // min-height: 600px;
    height:100%;
    margin: 0 auto; /* 블럭요소 가운데 정렬 */
    padding-bottom: $footer_size;//footer크기

    .search{//메인 탐색 부분
        // background-color: red;
        height: 100px;
        display:flex;
        justify-content: space-between;
        .job{
            display:flex;
            cursor:pointer;
            background-color: black;
            justify-content: center;
            width:100px;
            height:100px;
            color:white;
            .job_content{
                justify-content: center;
            }
            
        }
    }
    .search_start{
        display:flex;
        justify-content: flex-end;
    }
    
}

@import "option";

@import 'engrave';
@import 'result';

.footer{
    width:100%;
    height:$footer_size;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: lightblue;
}

@import 'modal';
@import 'harb';
@import 'ella'