.option{//옵션 선택
    margin:20px 0px;
    background-color: rgb(180, 180, 180);
    padding: 20px 10px;
    .option_content{//옵션 전체적인 형태
        display:flex;
        justify-content: center;
        width:100%;
        .option_wrap{
            width:900px;
            display:flex;
            justify-content: space-around;
        }
    }

    .slide_wrap{
        display: flex;
        flex-flow: column;
        align-items: center;
        .tekseng{//특성선택 영역
            width:300px;
            .tekseng_select_wrap{//특성 선택 감싸는것
                display:flex;
                justify-content: space-between;
                .tekseng_select_form{//각 특성 선택창
                    width: 90px;
                }
            }
        }
        .price_wrap{//효율 저가 특성조절
            margin:10px 0 10px 0;
            .price_select_wrap{
                display:flex;
                justify-content: space-between;
            }
        }
    }
    
    .abil_wrap{// 돌 고정
        display:flex;
        flex-flow:column;
        justify-content: space-between;
        align-items: center;
        margin:10px 0 10px 0;
        .abil_show_content{
            height:80px;
            overflow: hidden;
            .abil_engrave_wrap{
                display: flex;
                height:80px;
            }
            .abil_hidden{
                background-color: black;
                width:100%;
                height:80px;
                display:flex;
                justify-content: center;
            }
        }
        
    }
    .receipe_wrap{//각인서 고정
        display:flex;
        flex-flow:column;
        justify-content: space-between;
        margin:10px 0 10px 0;
        align-items: center;
        .receipe_show_content{
            height:80px;
            overflow: hidden;
            .receipe_engrave_wrap{
                display: flex;
                height:80px;
            }
            .receipe_hidden{
                background-color: black;
                width:100%;
                height:80px;
                display:flex;
                justify-content: center;
            }
        }
    }
    .engrave{
        display:flex;
        flex-flow:column;
        align-items: center;
        margin:0 5px;
        width:100px;
        cursor:pointer;
        .engrave_img{
            display:flex;
            justify-content: center;
            border-radius: 70%;
            overflow: hidden;
            width:37px;
            img{
                width:37px;
            }
        }
        .engrave_point{
            display:flex;
            align-items: center;
            justify-content: center;
            div{
                display: flex;
                align-items: center;
                justify-content: center;
                margin:0 4px;
                height:100%;
            }
            .down,.up{
                cursor:pointer;
            }
            img{
                width:10px;
            }
        }
        
    }
}