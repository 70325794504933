//틀생성
.select_form {
    display: block;
    position: relative;
    width: 140px;
    height: 26px;
    line-height: 26px;
    border: 1px solid white;
    text-align: left;
}
//초기화
.select_form select {
    width: 100%;
    height: 100%;
    padding: 0 30px 0 10px;
    background-color: white;
    border: 0 none;
    font-size: 16px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
//화살표생성
.select_form:after {
    position: absolute;
    top: 10px;
    right: 10px;
    background-image:url(https://cdn-icons-png.flaticon.com/512/32/32195.png);
    background-repeat: no-repeat;
    background-size: 13px 7px;
    width: 13px;
    height: 7px;
    display: block;
    content:'';
}